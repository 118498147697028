import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import CustomToolbar from "../../CustomToolbar";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import datatableTheme from "assets/css/datatable-theme.js";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import axios from 'axios';
import AddFormDialog from "./Components/AddFormDialog.js";

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} />;
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function FullWidthTabs() {
  const [items, setItems] = useState([]); //table items
  const [userProfileList, setUserProfileList] = useState([]); //table items
  const [userTypeList, setUserTypeList] = useState([]); //table items

  useEffect(() => {
    const fetchData = async () => {
      const userType = await axios(`${process.env.REACT_APP_BASE_URL}/userType`, {
        responseType: "json",
      }).then((response) => {
        setUserTypeList(response.data[0].data)
        return response.data[0].data
      });
      const userProfile = await axios(`${process.env.REACT_APP_BASE_URL}/userProfile`, {
        responseType: "json",
      }).then((response) => {
        setUserProfileList(response.data[0].data)
        return response.data[0].data
      });
      const users = await axios(`${process.env.REACT_APP_BASE_URL}/users/`, {
        responseType: "json",
      }).then((response) => {
        setItems(
          response.data[0].data.map((e) => {
            const userProfileItem= userProfile.filter(userProfileData=> e.profile_id===userProfileData.id)[0]
            return ({
              id: e.id,
              name: e.fullname,
              profile: userProfileItem.name,
            });
          })
        );
      });
    };
    fetchData();
  }, []);
  // const handleOnSubmit = async (e) => {
  //   console.log(e)
  //   await axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_BASE_URL}/users/`,
  //     data: [{
  //       "fullname": "William elTurk",
  //       "username": "Wturk",
  //       "password": "123456",
  //       "address": "Lebanon",
  //       "mobile": "71718971",
  //       "email": "williamelturk@gmail.com"
  //   }]
  //   }).then(function (response) {
  //     alert("success")
  //   }).catch(error => {
  //     console.log(error)
  // });
  
  // }
  
  const [disableAddress, setDisableAddress] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [userID, setUserID] = useState(); //modal title

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a
                onClick={() => {
                  console.log(tableMeta.rowData[0])
                  handleAdd(tableMeta.rowData[0]);
                }}
              >
                {value}
              </a>
            </div>
          );
        },
      },
    },
    {
      name: "profile",
      label: "Profile",
    },
  ];

  const options = {
    filterType: "dropdown",
    onRowsDelete: null,
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd();
          }}
        />
      );
    },
  };

  const handleAdd = (userID) => {
    setOpen(true);
    setUserID(userID);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeInput = (e) => {
    console.log(e.target.attributes.name.nodeValue)
    console.log(e.target.value)
  }
  return (
    <Container maxWidth="xl">
      <Autocomplete
        multiple
        id="tags-filled"
        options={top100Films.map((option) => option.title)}
        defaultValue={[]}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label=""
            placeholder="Search Data"
          />
        )}
      />

      <MuiThemeProvider theme={datatableTheme}>
        <MUIDataTable
          title=""
          data={items}
          columns={columns}
          options={options}
          className="dataTableContainer"
        />
      </MuiThemeProvider>

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AddFormDialog handleClose={handleClose} userProfileList={userProfileList} userTypeList={userTypeList} userID={userID} />
          {/* <AddFormDialog
            title={userID + " User"}
            handleClose={handleClose}
            inputs={[
              { labelText: "Name", ref:fullnameRef, name:"fullname", type: "text", handleChangeInput:handleChangeInput },
              {
                labelText: "Profile",
                type: "select",
                value: [
                  "Super Administrator",
                  "Client Profile",
                  "Operator",
                  "Administrator",
                  "Viewer",
                ],
              },
              { labelText: "Address", type: "text" },
              { labelText: "Mobile", type: "text" },
              { labelText: "Username", ref:usernameRef, type: "text" },
              { labelText: "Password", ref:passwordRef, type: "password" },
              { labelText: "Email", type: "text" },
              {
                labelText: "Recieve emails",
                type: "checkbox",
              },
              {
                labelText: "User type",
                type: "radio",
                value: ["Admin", "Client", "Technician/Driver"],
                grid: 12,
                setDisableAddress: setDisableAddress,
              },
              {
                labelText: "Can change to test",
                type: "checkbox",
                grid: 3,
              },
              {
                labelText: "Can Approve",
                type: "checkbox",
                grid: 3,
              },
              {
                labelText:
                  "Recieve Can Approve Recommended Preventive Maintenance",
                type: "checkbox",
                grid: 3,
              },
              {
                labelText: "Can Approve Damaged Fridges",
                type: "checkbox",
                grid: 3,
              },
              {
                labelText: "Client",
                type: "select",
                value: [
                  "Unilever Levant S.A.R.L.",
                  "Fattal Food & Beverages - Neo Comet",
                ],
                disabled: disableAddress,
              },
            ]}
            handleOnSubmit={handleOnSubmit}
          /> */}
        </Dialog>
      </div>
    </Container>
  );
}
